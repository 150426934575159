@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap');

a{
  color: inherit;
}


body, html{
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  
  /* color: #f3f3f3;
  background-color: #333; */
  background-color: #fff;
  color: #333;
  min-height: 100vh;
}
button{
    font-family: 'Lucida Sans',
    'Lucida Sans Regular',
    'Lucida Grande',
    'Lucida Sans Unicode',
    Geneva,
    Verdana,
    sans-serif;
    cursor: pointer;
  }

html{position: relative;}
h1, h2, h3, h4{
  font-weight: 400;
}


.page h1,
.page h2,
.page h3,
.page h4,
.page strong {
  /* color: orange; */
  color: #777;
}
.page b {
  color: rgb(146, 45, 32);
}

hr {
  color: white;
  background-color: white;
}

.wrapper{
  display: block;
  margin: auto;
  width: 900px;

}


header{
  background-color: #FF6E42;
  color: white;
  height: 120px;
  display: flex;
  align-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);


}

header .wrapper{
  padding: 1em 0 0 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

header h1{
  display: inline;
  font-size: 18pt;
  margin:0;
  display: flex;
  align-self: end;
  padding-right: 1em;

}

.header-navigation {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  display: none;
}

.header-navigation button{
  border-color:#FF6E42;
  border-bottom: 3px solid white;
  padding: 0.4em 0.3em 0.4em 0.2em;
  cursor: pointer;
}
.header-navigation li{
  margin-left: 1em;
}
.mainbody{
  display: flex;
  flex-direction: row;
  padding-bottom: 110px !important;
}


.sidebar{
  position: absolute;
  top: 0;
  left: 0;
  width: 234px;
  display: flex;
  flex-direction:column;
  align-self: stretch;
  background-color: #333;
  color: #eee;
  z-index: 1;
  height: 100%;
}
.sidebar-enter {
  opacity: 0;
}

.sidebar-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.sidebar-exit {
  opacity: 1;
}

.sidebar-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.overlay {
  box-sizing: border-box;
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #333;
  opacity: 0.6;
}

.sidebar h2{
  margin: 2em 1em 1em 1em;
}

.close-sidebar-btn{
  font-size: 20pt;
  cursor: pointer;
  user-select: none;
  width: min-content;
  margin: 1.5em 0 0 1em;
}
.open-sidebar-btn {
  margin: 1.5em 0 0 1em;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20pt;
  cursor: pointer;
  display: none;
  user-select: none;
}

.sidebar-navigation {
  background-color: #444;
  margin: 0;
  margin-top: 1em;
  height: 100%;
}

.sidebar-navigation button {
  border: none;
  border-top: 1px solid #444;
  width: 100%;
  padding: 1em;
  border-radius: 0;
  font-size: 12pt;
}

/* .sidebar-navigation button:first-child{
  border-top: 1px solid white;
} */

.mainbody{
  margin-top: 2em;
}
.mainbody .wrapper{
  padding: 0 1em;
}
.mainbody h1 {
  /* font-weight: lighter; */
  font-size: 24pt;
}

button{
  padding: .5em 1em;
  min-width: 100px;
  font-size: 13pt;
  border: 3px solid white;
  background-color: #FF6E42;
  border-radius: 5px;
  min-width: 94px;
  color: white;
}

.btn span>svg{
  width: 1.2em;
  display: block;
  float: left;
  margin-right: 0.3em;
  margin-top: 1.5px;
  /* outline: 1px solid red; */
}

.btn:active{
  transform: translateY(1px);
}

ul{
  list-style: none;
  padding: 0;
}
.page ul {
  padding: 0 2em;
  list-style: circle;
}

.page li {
  margin-bottom: 1em;
}
.page li a p{
 display: inline;
 margin-left: 1em;
}

.page a {
  text-decoration: none;
  color: rgb(226, 102, 0);
}



.page img{
  max-width: 100%;
  display: block;
  margin: 3em auto;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 0px rgb(255, 255, 255),
  0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
 }
.page h2,
.page h3,
.page h4
{
  padding-top: 1.5em;
}
.page p{
  line-height: 1.5em;
  font-size: 14pt;
}
#suggested-posts{
  margin-top: 3em;
  margin-bottom: 10em;
}
.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 3em;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

form{
  margin-top: 3em;
  max-width: 600px;
  margin: 3em auto;
}

form button{
  float: right;
  border-radius: 6px;
}

textarea,
input[type=text] {
  border: 2px solid lightgray;
  box-sizing: border-box;
  outline: none;
  border-radius: 6px;
  font: inherit;
  width: 100%;
  margin: 0.3em 0 1em 0;
  resize: none;
}

input[type=text] {
  width: 100%;
  padding: 12px 20px;
}
textarea {
  height: 150px;
  padding: 1em;
}

label{
  font-size: 1.2em;
  color: #666;
  margin-top: 20px;
}

*{
  /* outline: 1px solid red; */
}

.project_tile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project_tile > div {
  width: 120px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1 !important; 
  /* position: fixed;
  right: 100px; */
}
.project_tile svg {
  width: 30px !important;
  height: 30px !important;

}
.project_tile a {
  
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  padding: 0.4em;
  margin-right: 0.5em;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
  transition: 0.3s;
  background-color: white;
}
.project_tile a:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.project_card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  max-width: 300px;
}
.project_card img{
  margin: 0;
}
#projects_page {
  max-width: 1000px;
  /* display: none; */
}
#project_card>a {
  text-decoration: none;
  display: block;
  max-width: 300px;
}

.project_card h3 {
  margin: 0;
  margin-bottom: .5em;
  padding: 0 1em; 
  color: #444;
}

.project_card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.project_card_info {
  padding: 1em 0;
}

.project_card_info > .tags{
  display: flex;
  flex-flow: row;
  max-width: 300px;
  font-size: 10pt;
  padding: 0 1.5em;
}

.project_card_info> .tags > span {
  color:rgb(255, 255, 255);
  /* font-weight: bold; */
  background-color: rgb(255, 177, 43);
  padding: 0.3em;
  border: 2px solid orange;
  border-radius: 5px;
  float: right;
  margin-right: 0.5em;
  grid-template-columns: repeat(1, 1fr);

}
.projectcardscontainer{
    max-width: 900px;
    margin: 0 auto;
    /* display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-around; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2em;
    row-gap: 2em;
    padding: 2em  0;
}
.projectcardscontainer img{
  box-shadow: none;
}

 @media (min-width: 930px) {

  
    header h1 {
      padding-right: 0;
    }
    .options {
      display: inherit;
    }
    
    .open-sidebar-btn {
      display: none;
    }
    .sidebar, .overlay {
      display: none;
    }
}

 @media (max-width: 930px) {
    .projectcardscontainer {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 0;
      justify-items: center;
    }
    
    .open-sidebar-btn {
      display: block;
    }
    header {
     box-sizing: border-box;
     padding-left: 4em;
    }
    header .wrapper{
      justify-content: center;
    }
} 
 @media (max-width: 660px) {
    .projectcardscontainer {
      grid-template-columns: repeat(1, 1fr);
    }
    
    .project_card {
      max-width: 100%;
    }
} 

.features-cards-grid {
  padding: 0 !important;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}


.features-cards-grid h4 {
  font-size: 1.3em;
}

.features-cards-grid p {
  font-size: 1em;
}

.features-cards-grid > li{

  display: inline-flex;
  max-width: 400px !important;
  padding: 1em;
}

.features-cards-grid > li img,
.features-cards-grid > li div{
  width: 50%;
  margin-left: 1em;
}

.features-cards-grid > li > *{
  padding: 0;
  margin: 0;
}

.responsive-features-grid > li{
  display: flex;
  flex-direction: row;
  gap: 3em;
  /* justify-content: space-between; */
}
.responsive-features-grid > li > *{
  display: block;
  float: left;
  box-sizing: content-box;
}

.responsive-features-grid > li > div{
    /* max-width: 100%; */
    /* object-fit: contain; */
    min-width: 300px;
}

@media (max-width: 430px) {
  .features-cards-grid li {
    flex-flow: column-reverse;
    width: 100%;
    /* background-color: red; */
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  .features-cards-grid li div {
    width: 100%;
  }
}


@media (max-width: 900px) {

  .responsive-features-grid .content-row{
    /* background-color: blue !important; */
    flex-direction: column;
  }
  .page{
    padding: 3%;
  }
}



/* scrollbar style */
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #FF6E42 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #FF6E42;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

footer{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  height: 80px;
  background-color: #FF6E42;
  color: white;
  display: flex;
}
footer p{
  display: flex;
  margin: 0;
  align-items: center;
}
footer .wrapper{
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  padding: 0 1em;
}


footer ul{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  list-style: none;
}

footer svg{
  width:30px;
  height: 30px;
  margin-left: 1em;
}